html,
body {
  margin: 0;
  background-color: #000;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Bebas Neue";
  font-size: 5.4rem;
  line-height: 4.8rem;
}

h2 {
  font-family: "Bebas Neue";
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  font-style: italic;
  font-weight: 300;
  opacity: 0.8;
}

p {
  margin-bottom: 1rem;
  font-family: "Fira Sans";
  font-size: 1.6rem;
  letter-spacing: -0.05rem;
  font-weight: 300;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 3.8rem;
    line-height: 2.4rem;
  }
  h2 {
    font-size: 2.2rem;
  }

  p {
    font-size: 1.5rem;
  }
}
