$transform-duration: 550ms;
$left: -30%;
$right: 105%;

.pageTransitionEnter {
  &Right {
    transform: translate($right);
  }

  &Left {
    transform: translate($left);
  }
}

.pageTransitionEnterActive {
  box-shadow: 0px 10px 15px 26px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  transition: transform ease $transform-duration !important;
  transform: translate(0);
}

.pageTransitionExitActive {
  &Right {
    pointer-events: none;
    transition: transform ease $transform-duration !important;
    transform: translate($left);
  }

  &Left {
    box-shadow: 0px 10px 15px 26px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    transition: transform ease $transform-duration !important;
    transform: translate($right);
  }
}

.firstChild {
  position: absolute;
  // z-index: 1;
  // left: 0;
}

.secondChild {
  position: absolute;
  z-index: 2;
  // left: 0;
}
