* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}

.container {
  position: absolute;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;

  // overscroll-behavior: none;
}

section {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
