.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
  background-color: yellowgreen;
}
