.tileContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  // justify-content: center;
  // align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.equine {
  color: white;
  background: linear-gradient(#004c44, #00978c);
}

.natwest {
  color: white;
  background: linear-gradient(#704f9b, #4c2c6e);
}

.kfc {
  color: white;
  background: linear-gradient(#87171f, #e21f2e);
}

.footer {
  color: white;
  background: linear-gradient(#222222, #000000);
}

.divider {
  max-width: 60rem;
  border-top: 1px solid #ffffff33;
  padding-top: 2rem;
  margin-top: 2rem;
}
